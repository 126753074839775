<template>
  <div class="home">
    <div class="top">
      <img class="img" src="@/assets/image/zhiding.png" />
      <div class="introduce">
        <div class="in">Super fun games with amazing real cash rewards</div>
      </div>
    </div>
    <div class="container">
      <div class="introduce">
        <div class="left">
          <p class="title">Play to win rewards!</p>
          <p class="content">
            Come give yourself a treat by playing our games: Lucky Word, Lucky
            Tile, 2048, are all free download games with infinite fun. By
            playing our games, you will not only gain relaxing and fun game
            experience, but you will get amazing real cash rewards if you win!
          </p>
          <p class="content">
            Just download the app on your phone then you can start playing! Each
            games has different ways to play, follow the requirements and then
            you will get the rewards! All your winning rewards will be deposited
            in your “Wallet” within the game. Once you have reached the standard
            withdrawal amount, you are eligible to cash out. All cash outs are
            handled via PayPal.
          </p>
        </div>
        <div class="right">
          <img src="" alt="" class="img2" />
        </div>
      </div>
    </div>
    <div class="game" id="game">
      <img src="@/assets/image/2022.png" alt="" class="img" />
    </div>
    <div class="game">
      <img src="@/assets/image/2048.png" alt="" class="img" />
    </div>

    <div class="game">
      <img src="@/assets/image/tile.png" alt="" class="img" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;

  .top {
    width: 100%;
    position: relative;

    .img {
      width: 100%;
      height: auto;
    }
    .introduce {
      position: absolute;
      top: 25%;
      max-width: 1200px;
      margin: 0 auto;
      left: 0;
      right: 0;

      .in {
        width: 520px;
        text-align: left;
        color: #fff;
        font-size: 66px;
        font-family: Poppins;
      }
    }
  }
  .container {
    padding: 80px 0;
    max-width: 1200px;
    margin: 0 auto;
    // height: 800px;
    // background: pink;
    .introduce {
      display: flex;
      justify-content: space-between;
      .left {
        .title {
          font-family: Montserrat, sans-serif;
          text-align: left;
          font-weight: 800;
          color: #fc9e00;
          font-size: 58px;
        }
        .content {
          text-align: left;
          font-size: 16px;
          font-family: Poppins, sans-serif;
          letter-spacing: 0.14px;
        }
      }
      .right {
        width: 400px;
        .img {
          width: 400px;
        }
      }
    }
  }
  .game {
    .img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
