import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/our-winners",
    name: "our-winners",
    component: () => import("../views/WinnerView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("../views/FaqsView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/BlogView.vue"),
  },
  {
    path: "/term",
    name: "term",
    component: () => import("../views/TermView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
  },
  {
    path: "/show/:id",
    name: "show",
    component: () => import("../views/BlogShow.vue"),
  },
  {
    name: "404",
    path: "/404",
    component: () => import("@/views/notFound.vue"),
  },
  {
    path: "*", // 此处需特别注意至于最底部
    redirect: "/404",
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
    document.title = 'Pearlbuy - Super fun games with amazing real cash rewards'
  next()
})

export default router;
