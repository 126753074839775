<template>
  <div class="header" :style="{ top: show ? '0' : '-120px' }">
    <div class="container">
      <div class="nav">
        <!-- <div class="logo"> -->
        <img
          src="@/assets/image/logo.png"
          alt=""
          @click="toHome"
          class="logo"
        />
        <!-- </div> -->
        <div class="menu">
          <div class="menuItem">
            <router-link class="link" to="/">Home</router-link>
          </div>
          <div class="menuItem">
            <router-link class="link" to="/#game">Our games</router-link>
          </div>
          <div class="menuItem">
            <a class="link"  href="/#/our-winners">Our winners</a>
          </div>
          <div class="menuItem">
            <a class="link" href="/#/about">About us</a>
          </div>
          <div class="menuItem">
            <a class="link" href="/#/faqs">FAQs</a>
          </div>
          <div class="menuItem">
            <a class="link" href="/#/blog">Blog</a>
          </div>
          <div class="menuItem">
            <a class="link" href="mailto:Emmazhou@pearlbuy.info">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      show: true,
      top: "-100px",
      h: 0,
    };
  },
  methods: {
    toHome() {
      this.$router.push({
        path: "/",
      });
    },
    handleScroll() {
      if (window.scrollY > this.h) {
        this.h = window.scrollY;
        this.show = false;
      } else if (window.scrollY < this.h) {
        this.h = window.scrollY;
        this.show = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  .container {
    max-width: 1200px;
    padding: 35px 0;
    margin: 0 auto;
    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
      .logo {
        width: 200px;
        height: 70px;
      }

      .menu {
        width: 100%;
        margin-left: 30px;
        display: flex;
        justify-content: space-between;
        justify-items: center;
        .menuItem {
          .link {
            text-decoration: none;
            color: #fff;
            font-family: Montserrat, sans-serif;
            font-size: 1.3125rem;
            font-weight: 600;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
