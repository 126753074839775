<template>
  <div id="app">
    <nav>
      <Header></Header>
    </nav>
    <router-view />
    <footer>
      <Footer></Footer>
    </footer>
  </div>
</template>

<script>
import Header from "@/components/HeaderView.vue";
import Footer from "@/components/FooterView.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Poppins;
  src: url("@/assets/font/Poppins-Bold.ttf");
}
body {
  margin: 0;
}
img {
  border: 0;
  // vertical-align:top;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
