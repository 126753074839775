<template>
  <div class="footer">
    <img src="@/assets/image/footer.png" alt="" class="img" />
    <div class="container">
      <div class="footernav">
        <a href="/#/privacy" class="link">Privacy</a>
        <a href="/#/term" class="link">Terms</a>
        <a href="/#/blog" class="link">Blog</a>
      </div>
      <img src="@/assets/image/logo.png" class="logo" />
      <h2 style="color: #fff">Treat <span>Yourself!</span></h2>
    </div>
    <div class="footerp">
      <p>Copyright 2022 &copy; Pearlbuy <span>|</span></p>
      <p>
        All rights reserved.
        <span
          >Created by
          <strong>Pearlbuy</strong> limited</span
        >
      </p>
      <p>
        <span>
          <A HREF="mailto:Emmazhou@pearlbuy.info"> Support @ Pearlbuy</A></span
        >
        | 3/F YAU LEE CENTER NO 45 HOI YUEN ROAD KWUN TONG KL
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.footer {
  width: 100%;
  background: linear-gradient(120deg, #ff9311 8%, #603b9f 92%);
  background-size: 100% 62%, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, top;
  .img {
    width: 100%;
    height: auto;
  }
  .container {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footernav {
      width: 20%;
      display: flex;
      justify-content: space-between;
      .link {
        text-decoration: none;
        font-family: Poppins, sans-serif;
        font-size: 1rem;
        color: #fff;
        letter-spacing: 0.14px;
        line-height: 32px;
        background-color: transparent;
      }
    }
    .logo {
      width: 20%;
      margin: 20px 0;
    }
  }
  .footerp {
    color: #fff;
    width: 100%;
    padding: 32px 0;
    a {
      color: #fff;
    }
  }
}
</style>
